import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ObjectUtils from '../../utils/ObjectUtils';
import ActionTypes from '../form/ActionTypes';
import { Option } from '../Option';
import Accordion from '../shared/accordion/Accordion';
import Button, { ButtonType } from '../shared/form-control/Button';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import DeleteIcon from '../shared/icon/DeleteIcon';
import ConditionCombiner from './ConditionCombiner';
import { Action, FormBuilderForm } from './FormBuilderTypes';

type RiskPropertiesProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patchAction: (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: Action;
  form: FormBuilderForm;
};

const RiskProperties: FC<RiskPropertiesProps> = (props) => {
  const { action, patchAction, form } = props;
  const { riskEnabled, riskConditions, riskNoMatch } = action || {};

  const [disableRisk, setDisableRisk] = useState(false);

  const { t } = useTranslation('form-builder');

  const riskOptions = useMemo<Option<string, number>[]>(
    () => [
      {
        id: '0',
        text: t('action-properties.common.risk.no-risk'),
        value: 0,
      },
      {
        id: '1',
        text: t('action-properties.common.risk.low-risk'),
        value: 1,
      },
      {
        id: '2',
        text: t('action-properties.common.risk.medium-risk'),
        value: 2,
      },
      {
        id: '3',
        text: t('action-properties.common.risk.high-risk'),
        value: 3,
      },
      {
        id: 'Infinity',
        text: t('action-properties.common.risk.critical-risk'),
        value: Infinity,
      },
    ],
    [t],
  );

  const selectedRiskNoMatch = useMemo(() => {
    const selected = riskOptions.find((option) => option.value === (riskNoMatch === 'Infinity' ? Infinity : parseInt(riskNoMatch || '-1', 10)));
    if (selected) {
      return selected;
    }
    return riskOptions[0];
  }, [riskNoMatch, riskOptions]);

  useEffect(() => {
    if (!action?.type) return;

    const actionType = ActionTypes[action?.type];
    const sourceType = actionType?.conditionalsValueSource?.(action);

    const shouldDisableRisk = sourceType?.indexOf('\0') !== -1;
    setDisableRisk(shouldDisableRisk);
    // Exclude 'Affiliated Companies' from risk calculations
    if (riskEnabled === undefined || riskEnabled === null || (riskEnabled !== false && shouldDisableRisk)) {
      patchAction({ riskEnabled: false });
    }

    if (riskConditions === undefined || riskConditions === null) {
      patchAction({ riskConditions: [] });
    }
  }, [action, patchAction, riskConditions, riskEnabled]);

  const updateEnabled = (enabled: boolean) => {
    const conditions = enabled && riskConditions?.length === 0 ? [''] : riskConditions;
    patchAction({ riskEnabled: enabled, riskConditions: conditions });
  };

  const addCondition = () => {
    patchAction({ riskConditions: [...(riskConditions || []), ''] });
  };

  const updateCondition = (index: number, condition: string) => {
    if (!riskConditions) {
      return;
    }

    const copy = ObjectUtils.DeepClone(riskConditions);
    copy[index] = condition;
    patchAction({ riskConditions: copy });
  };

  const updateRiskScore = (index: number, value: number) => {
    if (!riskConditions) {
      return;
    }

    const copy = ObjectUtils.DeepClone(riskConditions);
    copy[index] = copy[index].split(' -> ')[0] + ` -> ${value},-1`;
    patchAction({ riskConditions: copy });
  };

  const updateNoMatchRiskScore = (value: number) => {
    patchAction({ riskNoMatch: `${value}` });
  };

  const removeCondition = (index: number) => {
    if (!riskConditions) {
      return;
    }

    const copy = ObjectUtils.DeepClone(riskConditions);
    copy.splice(index, 1);
    patchAction({ riskConditions: copy });

    if (copy.length === 0) {
      patchAction({ riskEnabled: false });
    }
  };

  return (
    <>
      {!disableRisk && (
        <div data-cy="risk-properties">
          <Accordion active={riskEnabled} title={<div className="font-medium">{t('action-properties.common.risk.title')}</div>}>
            <div className="mb-2 mt-2 flex items-center justify-between">
              <div className="text-dpm-12">{t('action-properties.common.risk.enable-heading')}</div>
              <div>
                <Checkbox
                  data-cy="contributes-to-risk"
                  value={riskEnabled}
                  onChange={(value) => updateEnabled(value)}
                  slider
                  sliderSize={SliderSize.S}
                />
              </div>
            </div>

            {riskEnabled && (
              <>
                {riskConditions?.map((riskCondition, i) => {
                  const [condition, returnValues] = riskCondition.split(' -> ');

                  const splitReturnValue = returnValues?.split(',')[0];
                  const riskLevel = splitReturnValue === 'Infinity' ? Infinity : parseInt(splitReturnValue || '-1', 10);
                  const riskText = riskOptions.find((x) => x.value === riskLevel)?.text;

                  return (
                    <div key={i} data-cy={`condition-${i}`}>
                      {i !== 0 && <div className="border-gray-5 my-4 border-b-2" />}

                      <div className="flex items-center justify-between" data-cy="condition-header">
                        <div className="font-medium text-black" data-cy="condition-name">
                          {t('action-properties.common.risk.condition-heading', { count: i + 1 })}
                        </div>
                        <DeleteIcon data-cy="remove-condition" className="h-6 w-6" onClick={() => removeCondition(i)} />
                      </div>

                      <ConditionCombiner
                        action={action}
                        actionCondition={condition}
                        form={form}
                        onConditionUpdated={(newCondition) => updateCondition(i, newCondition)}
                        lockToAction
                      />

                      <div className="my-4 flex items-center">
                        <div className="mr-2 font-medium">{t('action-properties.common.risk.score-heading')}</div>
                        <DropdownSelect
                          data-cy="risk-score"
                          options={riskOptions}
                          onChange={(o) => updateRiskScore(i, o.value as number)}
                          placeholder={t('action-properties.common.risk.score-heading')}
                          value={{ id: riskLevel.toString(), text: riskText || '', value: riskLevel }}
                        />
                      </div>
                    </div>
                  );
                })}

                <Button data-cy="add-condition" className="mt-4" type={ButtonType.PRIMARY} onClick={addCondition}>
                  {t('action-properties.common.risk.add-condition')}
                </Button>

                <div className="my-4 flex items-center" data-cy="no-condition-hint">
                  <div className="mr-2 font-medium">{t('action-properties.common.risk.no-condition-risk')}</div>
                  <DropdownSelect
                    data-cy="risk-no-match-score"
                    options={riskOptions}
                    onChange={(o) => updateNoMatchRiskScore(o.value as number)}
                    placeholder={t('action-properties.common.risk.score-heading')}
                    value={{ id: selectedRiskNoMatch.id, text: selectedRiskNoMatch.text, value: selectedRiskNoMatch.value }}
                  />
                </div>
              </>
            )}
          </Accordion>
        </div>
      )}
    </>
  );
};

export default RiskProperties;
